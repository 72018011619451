import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { createWrapper } from "next-redux-wrapper";
import type { $CombinedState } from "redux";

import { addressApi } from "../addressApi";
import { companyApi } from "../companyApi";
import { formManagerMiddleware, formManagerSlice } from "../formManager";
import { hydrationSlice } from "../hydration";
import { orderAddressApi } from "../orderAddressApi";
import { orderApi } from "../orderApi";
import { orderMessageApi } from "../orderMessageApi";
import { orderStateTransitionApi } from "../orderStateTransitionApi";
import { partApi } from "../partApi";
import { partCbsApi } from "../partCbsApi";
import { partUploadApi } from "../partUploadApi";
import { rfqApi } from "../rfqApi";
import { techTreeApi } from "../techTreeApi";
import { svcProfileApi, svcUserApi } from "../userApi";
import { sessionSlice } from "../userSession";
import { errorMiddleware } from "../utils/errorMiddleware";
import { emailPreferencesApi } from "../emailPreferencesApi";

export const rootReducer = combineReducers({
	[formManagerSlice.name]: formManagerSlice.reducer,
	[hydrationSlice.name]: hydrationSlice.reducer,
	[sessionSlice.name]: sessionSlice.reducer,
	[addressApi.reducerPath]: addressApi.reducer,
	[companyApi.reducerPath]: companyApi.reducer,
	[orderAddressApi.reducerPath]: orderAddressApi.reducer,
	[orderMessageApi.reducerPath]: orderMessageApi.reducer,
	[orderApi.reducerPath]: orderApi.reducer,
	[orderStateTransitionApi.reducerPath]: orderStateTransitionApi.reducer,
	[partUploadApi.reducerPath]: partUploadApi.reducer,
	[partApi.reducerPath]: partApi.reducer,
	[partCbsApi.reducerPath]: partCbsApi.reducer,
	[rfqApi.reducerPath]: rfqApi.reducer,
	[techTreeApi.reducerPath]: techTreeApi.reducer,
	[svcUserApi.reducerPath]: svcUserApi.reducer,
	[svcProfileApi.reducerPath]: svcProfileApi.reducer,
	[emailPreferencesApi.reducerPath]: emailPreferencesApi.reducer,
});

export const createStore = () => {
	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActionPaths: [
						"payload.fields",
						"payload.onSubmit",
						"meta.baseQueryMeta",
						"meta.arg.originalArgs",
					],
					ignoredPaths: [
						`${formManagerSlice.name}.formAttributes.fields`,
						`${formManagerSlice.name}.formAttributes.onSubmit`,
					],
				},
			})
				.prepend(formManagerMiddleware.middleware)
				.concat(
					errorMiddleware,
					svcUserApi.middleware,
					svcProfileApi.middleware,
					companyApi.middleware,
					addressApi.middleware,
					orderAddressApi.middleware,
					orderMessageApi.middleware,
					orderApi.middleware,
					orderStateTransitionApi.middleware,
					techTreeApi.middleware,
					partApi.middleware,
					partCbsApi.middleware,
					partUploadApi.middleware,
					rfqApi.middleware,
					emailPreferencesApi.middleware
				),
	});

	setupListeners(store.dispatch);

	return store;
};

export type AppStore = ReturnType<typeof createStore>;
export type RootState = ReturnType<AppStore["getState"]> & {
	readonly [$CombinedState]?: undefined;
};
export type AppDispatch = AppStore["dispatch"];

export const storeWrapper = createWrapper<AppStore>(createStore, {
	debug: false,
});
